import React, { useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import ProfileHeader from '~components/profiles/ProfileHeader';
import ProfileContext from '~components/profiles/ProfileContext';
import { RootStoreContext } from '~stores/rootStore';
import { RouteComponentProps } from 'react-router-dom';
import LoadingComponent from '~layout/LoadingComponent';
import { observer } from 'mobx-react-lite';
import { useAuth0 } from '@auth0/auth0-react';
import { useAsyncEffect } from 'use-async-effect';

interface RouteParams {
  username: string;
}

interface IProps extends RouteComponentProps<RouteParams> {}

const ProfilePage: React.FC<IProps> = ({ match }) => {
  const rootStore = useContext(RootStoreContext);
  const { loadProfile, profile, loadingProfile } = rootStore.profileStore;

  console.log(profile?.displayName);
  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();

  useAsyncEffect(
    async (isMounted) => {
      const accessToken = await getAccessTokenSilently({
        audience: `https://dev.bestkeptproperty.com/api`,
        scope: 'read:current_user',
      });

      window.localStorage.setItem('jwt', accessToken);

      await loadProfile(match.params.username, accessToken);
    },
    [isLoading, match]
  );
  // useEffect(() => {
  //   const accessToken = await getAccessTokenSilently({
  //     audience: `https://${domain}/api/v2/`,
  //     scope: "read:current_user",
  //   });
  //   loadProfile(match.params.username);
  // }, [loadProfile, match]);

  if (isLoading) return <LoadingComponent content="Loading profile..." />;

  return (
    <Grid>
      <Grid.Column width={16}>
        <ProfileHeader profile={profile!} />
        <ProfileContext />
        {profile?.displayName}
      </Grid.Column>
    </Grid>
  );
};

export default observer(ProfilePage);
