import { RootStore } from './rootStore';
import { User, UserFormValues } from '../common/services/models/user';
import { observable, computed, action, runInAction } from 'mobx';
import api from '../api/api';
import { history } from '../';

export default class UserStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable user: User | null = null;

  @computed get isLoggedIn() {
    return !!this.user;
  }

  @action getUser = async (token:string) => {
    try {
      const user = await api.UserService.current(token);
      runInAction(() => {
        this.user = user;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action login = async (values: UserFormValues) => {
    try {
      const user = await api.UserService.login(values);
      runInAction(() => {
        this.user = user;
      });
      this.rootStore.commonStore.setToken(user.token);
      this.rootStore.modalStore.closeModal();
      history.push('/properties');
    } catch (error) {
      throw error;
    }
  };

  @action register = async (values: UserFormValues) => {
    try {
      const user = await api.UserService.register(values);
      this.rootStore.commonStore.setToken(user.token);
      this.rootStore.modalStore.closeModal();
      // runInAction(() => {
      //   this.user = user;
      // });

      history.push('/properties');
    } catch (error) {
      throw error;
    }
  };

  @action logout = () => {
    this.rootStore.commonStore.setToken(null);
    this.user = null;
    history.push('/');
  };
}
