import React, { useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import ProfileHeader from '~components/profiles/ProfileHeader';
import ProfileContext from '~components/profiles/ProfileContext';
import { RootStoreContext } from '~stores/rootStore';
import { RouteComponentProps } from 'react-router-dom';
import LoadingComponent from '~layout/LoadingComponent';
import { observer } from 'mobx-react-lite';
import ProfilePage from '../profile/ProfilePage';
import { useAuth0 } from '@auth0/auth0-react';
import { useAsyncEffect } from 'use-async-effect';
interface RouteParams {
  username: string;
}

interface IProps extends RouteComponentProps<RouteParams> {}

export const PropertyCreatePage: React.FC<IProps> = ({ match }) => {
  const rootStore = useContext(RootStoreContext);
  const { loadProfile, profile, loadingProfile } = rootStore.profileStore;
  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();

  useAsyncEffect(
    async (isMounted) => {
      const accessToken = await getAccessTokenSilently({
        audience: `https://dev.bestkeptproperty.com/api`,
        scope: 'read:current_user',
      });

      await loadProfile(match.params.username, accessToken);
    },
    [isLoading, match]
  );

  if (loadingProfile) return <LoadingComponent content="Loading profile..." />;

  return (
    <Grid>
      <Grid.Column width={16}>Test</Grid.Column>
    </Grid>
  );
};

export default observer(PropertyCreatePage);
