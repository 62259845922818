import { Image } from '../common/services/models/profile';
import { RootStore } from './rootStore';
import { observable, action, runInAction, computed } from 'mobx';
import { Profile } from '../common/services/models/profile';
import api from '../api/api';
import { toast } from 'react-toastify';
import { Property } from '../common/services/models/property';
import PropertyApiService from '../common/services/property-api.service';

export default class PropertyStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable profile: Profile | null = null;
  @observable loadingPropertyDetails = true;
  @observable loadingPropertyImages = true;
  @observable loadingPropertiesForAgent = true;
  @observable uploadingPhoto = false;
  @observable loading = false;
  @observable property: Property | null = null;
  @observable properties: Property[] | null = null;

  @computed get isCurrentUser() {
    if (this.rootStore.userStore.user && this.profile) {
      return this.rootStore.userStore.user.userName === this.profile.userName;
    } else {
      return false;
    }
  }

  @action loadPropertiesForAgent = async (
    agentId: number,
    includeInactive: boolean = false
  ) => {
    this.loadingPropertiesForAgent = true;
    try {
      const properties = await PropertyApiService.getPropertiesForAgent(
        agentId
      );
      runInAction(() => {
        this.properties = properties;
        this.loadingPropertiesForAgent = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingPropertiesForAgent = false;
      });
      console.log(error);
    }
  };

  @action loadPropertyImages = async (id: string) => {
    this.loadingPropertyImages = true;
    try {
      let property: Property;
      if (!this.property) {
        property = await PropertyApiService.getProperty(id);
        this.property = property;
      }

      this.property.images = await PropertyApiService.getPropertyImages(id);  

      runInAction(() => {
        this.loadingPropertyImages = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingPropertyImages = false;
      });
      console.log(error);
    }
  };

  @action loadPropertyDetails = async (id: string) => {
    this.loadingPropertyDetails = true;
    try {
      const property = await PropertyApiService.getProperty(id);
      runInAction(() => {
        this.property = property;
        this.loadingPropertyDetails = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingPropertyDetails = false;
      });
      console.log(error);
    }
  };

  @action uploadPhoto = async (file: Blob) => {
    this.uploadingPhoto = true;
    try {
      const photo = await api.Profiles.uploadPhoto(file);
      runInAction(() => {
        if (this.profile) {
          this.profile.images.push(photo);
          if (photo.isMain && this.rootStore.userStore.user) {
            this.rootStore.userStore.user.image = photo.imageUrl;
            this.profile.image = photo.imageUrl;
          }
        }
        this.uploadingPhoto = false;
      });
    } catch (error) {
      console.log(error);
      toast.error('Problem uploading image');
      runInAction(() => {
        this.uploadingPhoto = false;
      });
    }
  };

  @action setMainPhoto = async (photo: Image) => {
    this.loading = true;
    try {
      await api.Profiles.setMainPhoto(photo.id);
      runInAction(() => {
        this.rootStore.userStore.user!.image = photo.imageUrl;
        this.profile!.images.find((a) => a.isMain)!.isMain = false;
        this.profile!.images.find((a) => a.id === photo.id)!.isMain = true;
        this.profile!.image = photo.imageUrl;
        this.loading = false;
      });
    } catch (error) {
      toast.error('Problem setting photo as main');
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action deletePhoto = async (photo: Image) => {
    this.loading = true;
    try {
      await api.Profiles.deletePhoto(photo.id);
      runInAction(() => {
        this.profile!.images = this.profile!.images.filter(
          (a) => a.id !== photo.id
        );
        this.loading = false;
      });
    } catch (error) {
      toast.error('Problem deleting the photo');
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
