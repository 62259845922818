import React, { useContext, useEffect } from 'react';
import {
  Segment,
  Item,
  Header,
  Button,
  Grid,
  Statistic,
  Divider,
  Reveal,
  Icon,
} from 'semantic-ui-react';
import { Profile } from '~common/services/models/profile';
import { observer } from 'mobx-react-lite';
import { Property, PropertyImage } from '~common/services/models/property';
// import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
// import 'pure-react-carousel/dist/react-carousel.es.css';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { RootStoreContext } from '~stores/rootStore';
import { useAsyncEffect } from 'use-async-effect';




const renderHtml = (content: string) => {
  return { __html: content };
}
 const ToImage = (data: string) => {return  { __html: `<img src=data:image/jpeg;base64,${data} />`}}



const PropertyDetails: React.FC<{propertyId: string}> = ({ propertyId }) => {

  const rootStore = useContext(RootStoreContext);
  const {
    loadPropertyDetails,
    loadPropertyImages,
    profile,
    property,
    loadingPropertyDetails,
    loadingPropertyImages,
  } = rootStore.propertyStore;



  useAsyncEffect(
    async (isMounted) => {
      //await loadPropertyDetails(propertyId);
      await loadPropertyImages(propertyId);
      // .then((res: any) => {
      //   props = res.data;
      // });
    }, [rootStore, loadPropertyDetails, loadPropertyImages]);


  return (
    <Segment>
      <Grid>
        <Grid.Column width={16}>
          <Item.Group>
            <Item>
              <Item.Content verticalAlign="middle">
                {/* <Grid.Column width={16} className='property-details-image'> */}
                {/* <Item.Image
                    size='large'
                    src={property.image || '/assets/user.png'}
                  /> 
                  </Grid.Column>
                  */}

                <div>
                  <Grid.Column width={16} height={100}>
                    <div className='carousel-wrapper'>
                      <Carousel showArrows={true} swipeable={true}>
{/* 
                          <div>
                            <img src='http://localhost:9444/bucketName/PS_44.PNG'/>
                          </div>
                          <div>
                            <img src='http://localhost:9444/bucketName/PS_44_and_45.PNG'/>
                          </div>
                          <div>
                            <img src='http://localhost:9444/bucketName/PS_45.PNG'/>
                          </div> */}

                        {/* <div>
                          <img src={`data:image/jpeg;base64,${property?.images[0]??''}`} />
                        </div> */}
                        {property?.images?.map((image: PropertyImage) => (
                          <div>
                            <img src={`http://localhost:9444/bucketName/${image.imageKey}`} />
                          </div>))}
                      </Carousel>

                      {/* <CarouselProvider
                        naturalSlideWidth={100}
                        naturalSlideHeight={125}
                        totalSlides={3}>

                        <Slider>
                          <Slide index={0}>
                            <Item.Image
                              size='large'

                              src={property.image || '/assets/user.png'}
                            /></Slide>
                          <Slide index={1}>I am the second Slide.</Slide>
                          <Slide index={2}>I am the third Slide.</Slide>

                        </Slider>
                        <ButtonBack className='slider-button-back'>Back</ButtonBack>
                        <ButtonNext className='slider-button-next'>Next</ButtonNext>
                      </CarouselProvider> */}
                    </div>
                  </Grid.Column>
                </div>
                <div>
                  <Grid.Column width={16}>
                    <Header as="h1">{property?.title}</Header>
                    <div className='property-icons-group'>
                      <div className='property-type'>{property?.propertyType.propertyTypeName}</div>
                      <div className='property-block-size'>{property?.blockSizeInSquareMetres} m²</div>
                      <div className='property-icons'>{property?.numberOfBedrooms} <Icon size='large' color='teal' name='bed' /></div>
                      <div className='property-icons'>{property?.numberOfBathrooms} <Icon size='large' color='teal' name='bath' /></div>
                      <div className='property-icons'>{property?.numberOfCarSpaces} <Icon size='large' color='teal' name='car' /></div>
                    </div>
                    <div className='property-address'>{property?.address?.streetNumber} {property?.address?.streetName} {property?.address?.streetType?.description}, {property?.address?.suburb?.suburbName} </div>
                    <div dangerouslySetInnerHTML={renderHtml(property?.description ?? '')} ></div>
                  </Grid.Column>
                </div>
              </Item.Content>
            </Item>
          </Item.Group>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default observer(PropertyDetails);