import React from 'react';
import {
  Segment,
  Item,
  Header,
  Button,
  Grid,
  Statistic,
  Divider,
  Reveal,
  Icon,
} from 'semantic-ui-react';
import { Profile } from '~common/services/models/profile';
import { observer } from 'mobx-react-lite';
import { Property } from '~common/services/models/property';
import { Link } from 'react-router-dom';

const renderHtml = (content: string) => {
  return { __html: content };
}

const buildPropertyLink = (id: number) => {
  return '/property/' + id;
}

const PropertyCard: React.FC<{ property: Property }> = ({ property }) => {
  return (
    <Segment>
      <Grid>
        <Grid.Column width={16} key={property.id}>
          <Item.Group>
            <Item  as={Link} to={buildPropertyLink(property.id)}>
              <Item.Image
                avatar
                size='small'
                src={property.image || '/assets/user.png'}
              />
              <Item.Content>
                <div className='property-icons-group'>
                    <div className='property-type'>{property.propertyType.propertyTypeName}</div>
                    <div className='property-block-size'>{property.blockSizeInSquareMetres} m²</div>
                    <div className='property-icons'>{property.numberOfBedrooms} <Icon size='large' color='teal' name='bed' /></div>
                    <div className='property-icons'>{property.numberOfBathrooms} <Icon size='large' color='teal' name='bath' /></div>
                    <div className='property-icons'>{property.numberOfCarSpaces} <Icon size='large' color='teal' name='car' /></div>
                </div>

                <div className='property-address'>{property.address?.streetNumber} {property.address?.streetName} {property.address?.streetType?.description}, {property.address?.suburb?.suburbName} </div>
                <div dangerouslySetInnerHTML={renderHtml(property.description)} ></div>
              </Item.Content>

            </Item>
          </Item.Group>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default observer(PropertyCard);
