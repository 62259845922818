import { Image } from '~common/services/models/profile';
import { User, UserFormValues } from '~common/services/models/user';
import { v4 as uuid } from 'uuid';
import { history } from '../';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Profile } from '~common/services/models/profile';
import { useAuth0 } from '@auth0/auth0-react';

axios.defaults.baseURL = 'https://localhost:5001/api';

axios.interceptors.request.use(
  async (config) => {
    //const token = window.localStorage.getItem('jwt');
    // const {
    //   getAccessTokenSilently,
    // } = useAuth0();

    let token = window.localStorage.getItem('jwt');

    // if (!token) {
    //   await getAccessTokenSilently({
    //     audience: `https://dev.bestkeptproperty.com/api`,
    //     scope: 'read:current_user',
    //   }).then((res) => {
    //     token = res;
    //     window.localStorage.setItem('jwt', token);
    //   });
    // }

    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    console.log('error: ' + JSON.stringify(error));
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error) => {
  if (!error || (!!error && error === {})) {
    return;
  } else {
    console.log('Error: ' + JSON.stringify(error));
  }

  if (error.message === 'Network Error' && !error.response) {
    toast.error('Network error!');
  }
  //const { status, data, config } = error.response;
  //const { status, data, config } = error.response;

  // if (status === 404) {
  //   history.push('/notfound');
  // }
  // if (
  //   status === 400 &&
  //   config.method === 'get' &&
  //   data.errors.hasOwnProperty('id')
  // ) {
  //   history.push('/notfound');
  // }
  // if (status === 500) {
  //   toast.error('Server error - check the terminal for more info!');
  // }

  throw error.response;
});

const responseBody = (response: AxiosResponse) => response.data;

const sleep = (ms: number) => (response: AxiosResponse) =>
  new Promise<AxiosResponse>((resolve) =>
    setTimeout(() => resolve(response), ms)
  );

const sleepAmount: number = 1000;

const requests = {
  get: (url: string, body: {} = {}) => axios.get(url, body).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
  postForm: (url: string, file: Blob) => {
    let formData = new FormData();
    let fileName = `${uuid()}.jpeg`;
    formData.append('File', file, fileName);
    return axios
      .post(url, formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
};

const UserService = {
  current: (token: string): Promise<User> => requests.get(`/user/${token}`),
  login: (user: UserFormValues): Promise<User> =>
    requests.post(`/user/login`, user),
  register: (user: UserFormValues): Promise<User> =>
    requests.post(`/user/register`, user),
};

// const getPropertiesForAgent = (
//   agentId: number,
//   includeInactive?: boolean
// ): Promise<Property[]> => {
//   return api.requests.post(`${PROPERTY_SUBPATH}/agent`,
//     {
//       agentId: agentId.toString(),
//       postcode: '',
//       includeInactive: !!includeInactive ? 'true' : 'false',

//     });
// };

const Profiles = {
  get: (username: string, accessToken: string): Promise<Profile> =>
    axios
      .get<Profile>(`/persons/${username}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then(responseBody),
  uploadPhoto: (photo: Blob): Promise<Image> =>
    requests.postForm(`/user/addimage`, photo),
  setMainPhoto: (id: string) => requests.post(`/user/setmain/${id}`, {}),
  deletePhoto: (id: string) => requests.del(`/user/deleteimage/${id}`),
};

// const Props = {
//   get: (username: string, accessToken: string): Promise<Profile> =>
//   axios.get(`/persons/${username}`, {
//     headers: { Authorization: `Bearer ${accessToken}` },
//   }),
//   loadPropertiesForAgent = async (
//     agentId: number,
//     includeInactive: boolean = false
//   ) => {
//     this.loadingPropertiesForAgent = true;
//     try {
//       const properties = await PropertyApiService.getPropertiesForAgent(
//         agentId
//       );
//   };

// }

export default {
  requests,
  UserService: UserService,
  Profiles,
};
