import React, { useContext, Fragment, useEffect } from 'react';
import { Container, Segment, Header, Button, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { RootStoreContext } from '../stores/rootStore';
import LoginForm from '../components/auth/LoginForm';
import RegisterForm from '../components/auth/RegisterForm';
import { useAuth0 } from '@auth0/auth0-react';
import { useAsyncEffect } from 'use-async-effect';

const HomePage = () => {
  const rootStore = useContext(RootStoreContext);

  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently
  } = useAuth0();
  const { openModal } = rootStore.modalStore;
  const { loginWithRedirect } = useAuth0();
  const { loadProfile, profile, loadingProfile } = rootStore.profileStore;
  const { getUser } = rootStore.userStore;


  useAsyncEffect(
    async (isMounted) => {
      if (!!user && isAuthenticated) {
        const accessToken = await getAccessTokenSilently({
          audience: `https://dev.bestkeptproperty.com/api`,
          scope: 'read:current_user',
        });

        //window.localStorage.setItem('jwt', accessToken);

        if (!!accessToken) {
          if (!!user && isAuthenticated) {
            if (user.sub) {
              await loadProfile(user.sub, accessToken);
              console.log('profile: ' + JSON.stringify(profile));
            }
          }
        }
      }
    },
    [isLoading, loadProfile, profile, user, isAuthenticated, isLoading, getAccessTokenSilently]
  );

  // useEffect(() => {
  //   if (!!user && isAuthenticated) {
  //     if (user.sub) {
  //       loadProfile(user.sub,)
  //       getUser(user.sub).then((val) => {

  //         console.log('user val: ' + val);
  //       }
  //       );
  //       //.finally(() => setAppLoaded());
  //     }
  //   }

  // })

  return (
    <Segment inverted textAlign="center" vertical className="masthead">
      <Container text>
        <Header as="h1" inverted>
          <Image
            size="massive"
            src="/assets/logo.png"
            alt="logo"
            style={{ marginBottom: 12 }}
          />
          AgentPrimer
        </Header>
        {isAuthenticated && user ? (
          <Fragment>
            <Header as="h2" inverted content={`Welcome back ${user.name}`} />
            <Button as={Link} to="/properties" size="huge" inverted>
              Go to properties
            </Button>
          </Fragment>
        ) : (
            <Fragment>
              <Header as="h2" inverted content="Welcome to AgentPrimer" />
              <Button onClick={() => loginWithRedirect()} size="huge" inverted>
                Login
            </Button>
              <Button
                onClick={() => openModal(<RegisterForm />)}
                size="huge"
                inverted
              >
                Register
            </Button>
            </Fragment>
          )}
      </Container>
    </Segment>
  );
};

export default HomePage;
