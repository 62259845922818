import React, { useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import ProfileHeader from '~components/profiles/ProfileHeader';
import ProfileContext from '~components/profiles/ProfileContext';
import { RootStoreContext } from '~stores/rootStore';
import { RouteComponentProps } from 'react-router-dom';
import LoadingComponent from '~layout/LoadingComponent';
import { observer } from 'mobx-react-lite';
import PropertyDetails from '~components/houseOrProperty/PropertyDetails';
import { useAsyncEffect } from 'use-async-effect';

interface RouteParams {
  id: string;
}

interface IProps extends RouteComponentProps<RouteParams> {}

const PropertyPage: React.FC<IProps> = ({ match }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadPropertyDetails,
    loadPropertyImages,
    property,
    profile,
    loadingPropertyDetails,
  } = rootStore.propertyStore;



  useAsyncEffect(
    async (isMounted) => {
      await rootStore.propertyStore.loadPropertiesForAgent(profile?.agencyId??0);
      await loadPropertyDetails(match.params.id);
      // .then((res: any) => {
      //   props = res.data;
      // });
    }, [rootStore, loadPropertyDetails, loadPropertyImages, match]);

      
  if (loadingPropertyDetails)
    return <LoadingComponent content="Loading property details..." />;

   return <PropertyDetails propertyId={match.params.id} />;
};

export default observer(PropertyPage);
