import React, { useContext, useEffect } from 'react';
import { Grid } from 'semantic-ui-react';
import ProfileHeader from '~components/profiles/ProfileHeader';
import ProfileContext from '~components/profiles/ProfileContext';
import { RootStoreContext } from '~stores/rootStore';
import { RouteComponentProps } from 'react-router-dom';
import LoadingComponent from '~layout/LoadingComponent';
import { observer } from 'mobx-react-lite';
import PropertyDetails from '~components/houseOrProperty/PropertyDetails';
import PropertyCard from '~components/houseOrProperty/PropertyCard';
import { Property } from '~common/services/models/property';
import { useAsyncEffect } from 'use-async-effect';
import PropertyApiService from '../../common/services/property-api.service';
import { PROPERTY_SUBPATH } from '~common/constants/api-subpaths';
import axios, { AxiosResponse } from 'axios';


interface RouteParams {
  id: string;
}

interface IProps extends RouteComponentProps<RouteParams> { }

const PropertyListPage: React.FC<IProps> = ({ match }) => {
  const rootStore = useContext(RootStoreContext);
  const {
    loadPropertiesForAgent,
    properties,
    profile,
    loadingPropertiesForAgent,
  } = rootStore.propertyStore;


  let props: Property[] = [];

  // axios.get(url, body).then(sleep(sleepAmount)).then(responseBody),
  //   let props: Property[] = [];
  //   console.log('blah: ' + PROPERTY_SUBPATH);
  //   axios.get(`https://localhost:5001/api/${PROPERTY_SUBPATH}/agent`).then((response: AxiosResponse) => {
  //     props = response.data;
  //   })
  //   .catch((error) => {
  //     console.log('error blah: ' + JSON.stringify(error));
  //   });


  useAsyncEffect(
    // () => {
    async (isMounted) => {
      //await loadPropertiesForAgent(1);

      //PropertyApiService.getPropertiesForAgent(1)
      await rootStore.propertyStore.loadPropertiesForAgent(profile?.agencyId??0);
      // .then((res: any) => {
      //   props = res.data;
      // });
    }, [rootStore, match]);

  // if (loadingPropertiesForAgent)
  //   return <LoadingComponent content="Loading properties..." />;

  //let props = await PropertyApiService.getPropertiesForAgent(1);

  return (
    <Grid>
      <Grid.Column width={16}>
        {rootStore.propertyStore.properties?.map((prop: Property) => (
          // <div key={prop.id}>
          //   <div>{prop.propertyType.propertyTypeName}</div>
          //   <div>{prop.id}</div>
          //   <div>{prop.address?.streetNumber} {prop.address?.streetName} {prop.address?.streetType?.description}</div>
          // </div>
           <PropertyCard property={prop!} />
        ))}
      </Grid.Column>
    </Grid>)
};


export default observer(PropertyListPage);
