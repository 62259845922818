import React, { Fragment, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { RootStoreContext } from './stores/rootStore';
import LoadingComponent from './layout/LoadingComponent';
import ModalContainer from './common/modals/ModelContainer';
import ProfilePage from './pages/profile/ProfilePage';
import PropertyPage from './pages/property/PropertyPage';
import PropertyListPage from './pages/property/PropertyListPage';
import { useAuth0 } from '@auth0/auth0-react';
import { AppRoutes } from './AppRoutes';

const App: React.FC<RouteComponentProps> = ({ location }) => {
  const rootStore = useContext(RootStoreContext);

  const { setAppLoaded, token, appLoaded } = rootStore.commonStore;
  const { getUser } = rootStore.userStore;
  const {
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently
  } = useAuth0();

  useEffect(() => {
    if (token) {
      setAppLoaded();

      //  if (!!user && user !== {}) {
      //   getUser(user.sub).then((val) => {
      //     console.log('user val: ' + val);
      //   }
      //   ).finally(() => setAppLoaded());
      //}
    } else {
      setAppLoaded();
    }
  }, [getUser, setAppLoaded, token]);

  if (!appLoaded) return <LoadingComponent content="Loading Application..." />;

  return (
    <Fragment>
      <ModalContainer />
      <ToastContainer position="bottom-right" />
      <AppRoutes></AppRoutes>
    </Fragment>
  );
};

export default withRouter(observer(App));
