import React from 'react';
import {
  Segment,
  Item,
  Header,
  Button,
  Grid,
  Statistic,
  Divider,
  Reveal,
} from 'semantic-ui-react';
import { Profile } from '~common/services/models/profile';
import { observer } from 'mobx-react-lite';
import { useAuth0 } from '@auth0/auth0-react';

const ProfileHeader: React.FC<{ profile: Profile }> = ({ profile }) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  return (
    <Segment>
      <Grid>
        <Grid.Column width={12}>
          <Item.Group>
            <Item>
              <Item.Image
                avatar
                size="small"
                src={user.image || '/assets/user.png'}
              />
              <Item.Content verticalAlign="middle">
                <Header as="h1">{user.name}</Header>
              </Item.Content>
            </Item>

            <Item>
              Is Active:
              <Item.Content verticalAlign="middle">
                <Header as="h3">{profile?.isActive ? 'true' : 'false'}</Header>
              </Item.Content>
            </Item>

          </Item.Group>
        </Grid.Column>
        <Grid.Column width={4}>
          <Statistic.Group widths={2}>
            <Statistic label="Followers" value="5" />
            <Statistic label="Following" value="42" />
          </Statistic.Group>
          <Divider />
          <Reveal animated="move">
            <Reveal.Content visible style={{ width: '100%' }}>
              <Button fluid color="teal" content="Following" />
            </Reveal.Content>
            <Reveal.Content hidden>
              <Button
                fluid
                basic
                color={true ? 'red' : 'green'}
                content={true ? 'Unfollow' : 'Follow'}
              />
            </Reveal.Content>
          </Reveal>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default observer(ProfileHeader);
