//PropertyCreatePage
import React, { Fragment } from 'react';

import { Route } from 'react-router';
import NotFound from '~layout/NotFound';
import ProfilePage from '~pages/profile/ProfilePage';
import PropertyPage from '~pages/property/PropertyPage';
import { Container } from 'semantic-ui-react';
import NavBar from './components/nav/NavBar';
import { Redirect, Switch } from 'react-router-dom';
import HomePage from './pages/HomePage';
import PropertyCreatePage from '~pages/property/PropertyCreatePage';
import PropertyListPage from '~pages/property/PropertyListPage';
import PropertyDetails from '~components/houseOrProperty/PropertyDetails';

const AppChildRoutes: React.FC = () => (
  <Switch>
    <Route exact path="/create-property" component={PropertyCreatePage} />
    <Route exact path="/property/:id" component={PropertyPage} />
    <Route exact path="/properties" component={PropertyListPage} />
    <Route exact path="/profile/:username" component={ProfilePage} />
    {/* default */}
    <Route component={NotFound} />
  </Switch>
);

export const AppRoutes: React.FC = () => {
  return (
    <>
      <Route exact path="/" component={HomePage} />
      <Route
        path={'/(.+)'}
        render={() => (
          <Fragment>
            <NavBar></NavBar>
            <Container style={{ marginTop: '7em' }}>
              <AppChildRoutes></AppChildRoutes>
            </Container>
          </Fragment>
        )}
      />
    </>
  );
};
