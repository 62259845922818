import { Image } from '../common/services/models/profile';
import { RootStore } from './rootStore';
import { observable, action, runInAction, computed } from 'mobx';
import { Profile } from '../common/services/models/profile';
import api from '../api/api';
import { toast } from 'react-toastify';

export default class ProfileStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @observable profile: Profile | null = null;
  @observable loadingProfile = true;
  @observable uploadingPhoto = false;
  @observable loading = false;

  @computed get isCurrentUser() {
    if (this.rootStore.userStore.user && this.profile) {
      return this.rootStore.userStore.user.userName === this.profile.userName;
    } else {
      return false;
    }
  }

  @action loadProfile = async (username: string, accessToken: string) => {
    this.loadingProfile = true;
    try {
      let profile  = this.rootStore.profileStore.profile;
      if (!profile && profile === {}){
        profile = await api.Profiles.get(username, accessToken);  
      }

      runInAction(() => {
        this.profile = profile;
        //?.data as Profile;
        this.loadingProfile = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loadingProfile = false;
      });
      console.log(error);
    }
  };

  @action uploadPhoto = async (file: Blob) => {
    this.uploadingPhoto = true;
    try {
      const photo = await api.Profiles.uploadPhoto(file);
      runInAction(() => {
        if (this.profile) {
          this.profile.images.push(photo);
          if (photo.isMain && this.rootStore.userStore.user) {
            this.rootStore.userStore.user.image = photo.imageUrl;
            this.profile.image = photo.imageUrl;
          }
        }
        this.uploadingPhoto = false;
      });
    } catch (error) {
      console.log(error);
      toast.error('Problem uploading image');
      runInAction(() => {
        this.uploadingPhoto = false;
      });
    }
  };

  @action setMainPhoto = async (photo: Image) => {
    this.loading = true;
    try {
      await api.Profiles.setMainPhoto(photo.id);
      runInAction(() => {
        this.rootStore.userStore.user!.image = photo.imageUrl;
        this.profile!.images.find((a) => a.isMain)!.isMain = false;
        this.profile!.images.find((a) => a.id === photo.id)!.isMain = true;
        this.profile!.image = photo.imageUrl;
        this.loading = false;
      });
    } catch (error) {
      toast.error('Problem setting photo as main');
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  @action deletePhoto = async (photo: Image) => {
    this.loading = true;
    try {
      await api.Profiles.deletePhoto(photo.id);
      runInAction(() => {
        this.profile!.images = this.profile!.images.filter(
          (a) => a.id !== photo.id
        );
        this.loading = false;
      });
    } catch (error) {
      toast.error('Problem deleting the photo');
      runInAction(() => {
        this.loading = false;
      });
    }
  };
}
