import { PROPERTY_SUBPATH, STORAGE_SUBPATH } from '../constants/api-subpaths';
import api from '~api/api';
import { Property } from './models/property';
import { PropertySearchParams } from './models/property-search-params';

const getAll = (): Promise<Property[]> => {
  return api.requests.get(`${PROPERTY_SUBPATH}`);
};

const getProperty = (id: string): Promise<any> => {
  return api.requests.get(`${PROPERTY_SUBPATH}/${id}`);
};

const getPropertyImages = (id: string): Promise<any> => {
  return api.requests.get(`${STORAGE_SUBPATH}/GetImages/${id}`);
};

const getPropertiesSearch = (
  postcode: string,
  includeInactive?: boolean
): Promise<Property[]> => {
  return api.requests.get(`${PROPERTY_SUBPATH}/search`, {
    params: {
      postcode,
      includeInactive: !!includeInactive ? 'true' : 'false',
    },
  });
};

const getFilteredProperties = (
  propertySearchParams: PropertySearchParams,
  includeInactive?: boolean
): Promise<Property[]> => {
  const postcode = propertySearchParams.postcode;
  return api.requests.get(`${PROPERTY_SUBPATH}/search`, {
    params: {
      postcode,
      includeInactive: !!includeInactive ? 'true' : 'false',
    },
  });
};

const getPropertiesForAgent = (
  agentId: number,
  includeInactive?: boolean
): Promise<Property[]> => {
  return api.requests.post(`${PROPERTY_SUBPATH}/agent`,
    {
      agentId: agentId.toString(),
      postcode: '',
      includeInactive: !!includeInactive ? 'true' : 'false',

    });
};

const upsertProperty = (request: FormData): Promise<any> => {
  return api.requests.post(`${PROPERTY_SUBPATH}`, request);
};

const deleteProperty = (id: number): Promise<any> => {
  return api.requests.del(`${PROPERTY_SUBPATH}/${id}`);
};

export default {
  getAll,
  getProperty,
  getPropertiesSearch,
  getFilteredProperties,
  getPropertiesForAgent,
  upsertProperty,
  deleteProperty,
  getPropertyImages
};
