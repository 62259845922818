import React from 'react'
import { Tab, TabPane } from 'semantic-ui-react';
import ProfilePhotos from './ProfilePhotos';


const panes = [
  { menuItem: "About", render: () => <Tab.Pane>About content</Tab.Pane> },
  { menuItem: "Photos", render: () => <ProfilePhotos /> },
  {
    menuItem: "Activities", render: () => <Tab.Pane>Activities content</Tab.Pane>,
  },
  {
    menuItem: "Followers", render: () => <Tab.Pane>Followers content</Tab.Pane>,
  },
  {
    menuItem: "Following", render: () => <Tab.Pane>Following content</Tab.Pane>,
  },
];




const ProfileContext = () => {
  return (
    <div>
      <div><div className="ui text menu"><a className="active item">Tab 1</a><a className="item">Tab 2</a><a className="item">Tab 3</a></div><div className="ui segment active tab">Tab 1 Content</div></div>

      {/* <Tab
        menu={{ fluid: true, vertical: true }}
        menuPosition='right'
        panes={panes}
        activeIndex={1}
      /> */}
    </div>
  )
}

export default ProfileContext
